import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));


// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));

const NotFound = lazy(() => import("./pages/other/google"));
const CashPurhase = lazy(() => import("./pages/other/CashPurhase"));
const Services= lazy(() => import("./pages/other/Services"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router basename="/">
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={ "/"}
                  component={HomeFashion}
                />

               
                <Route
                  path={ "/arac-listesi"}
                  component={ShopGridStandard}
                />
                
                <Route 
                path={ "/nakit-alim"}
                component={CashPurhase}
                />
            
                <Route
                  path={ "/shop-grid-standard"}
                  component={ShopGridStandard}
                />
              
                <Route
                  path={ "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
               

                <Route
                  path={ "/hakkimizda"}
                  component={About}
                />
                <Route
                  path={ "/hizmetlerimiz"}
                  component={Services}
                />
                <Route
                  path={ "/iletisim"}
                  component={Contact}
                />
               
                <Route
                  path={ "/not-found"}
                  component={NotFound}
                />
              

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
